<app-toolbar appinfo="appinfo" titel="Beheer Relying Parties"></app-toolbar>
<div class="content" fxLayout="column">

  <button mat-raised-button (click)="RecordToevoegen()" style="margin: 8px; margin-left:0; width:300px;">Relying Party toevoegen</button>

  <table mat-table matSort [dataSource]="dataSource">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="Details(row)" class="clickable"></tr>
  
    <ng-container matColumnDef="naam">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Naam</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.naam }}
      </td>
    </ng-container>
    <ng-container matColumnDef="clientId">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>clientId</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.client_Id }}
      </td>
    </ng-container>
    <ng-container matColumnDef="redirectUri">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Redirect</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.redirectUri }}
      </td>
    </ng-container>
   
  </table>

</div>
