import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { ClaimMapping } from '../models/claim-mapping';
import { CustomHttpParamEncoder } from './custom-http-param-encoder';

@Injectable({
  providedIn: 'root'
})
export class ClaimMappingService {

  constructor(private http: HttpClient, private appConfigSvc: AppConfigService) { }

  get(identityProviderId: number, identityProviderSubClaimValue: string) {

      let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .append('identityProviderId', identityProviderId ? identityProviderId.toString() : '')
      .append('identityProviderSubClaimValue', identityProviderSubClaimValue ? identityProviderSubClaimValue : '');
    return this.http.get<ClaimMapping[]>(this.appConfigSvc.getConfig().apiRoot + '/ClaimMapping', { params: params });
  }

  save(claimMapping: ClaimMapping) {

    if (claimMapping.claimMappingId) {
      return this.http.put<ClaimMapping>(this.appConfigSvc.getConfig().apiRoot + '/ClaimMapping/' + claimMapping.claimMappingId, claimMapping);
    } else {
      return this.http.post<ClaimMapping>(this.appConfigSvc.getConfig().apiRoot + '/ClaimMapping', claimMapping);
    }
  }

  delete(claimMapping: ClaimMapping) {
    return this.http.delete(this.appConfigSvc.getConfig().apiRoot + '/ClaimMapping/' + claimMapping.claimMappingId)
  }
}
