import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceToken } from '../models/device-token';
import { DeviceTokenService } from '../services/device-token.service';

@Component({
  selector: 'app-device-token',
  templateUrl: './device-token.component.html',
  styleUrls: ['./device-token.component.scss']
})
export class DeviceTokenComponent implements OnInit {

  device: DeviceToken;
  deviceForm: FormGroup = this.getDeviceFormGroup(null);

  @ViewChild('naamInput', { static: true }) naamInput: ElementRef;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private deviceTokenSvc: DeviceTokenService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {

    const deviceTokenId: string = this.route.snapshot.params.deviceTokenId;

    if (!deviceTokenId) {

      this.device = new DeviceToken();
      this.device.geldig = true;

      this.deviceForm = this.getDeviceFormGroup(this.device);
      this.naamInput.nativeElement.focus();

    } else {
      this.deviceTokenSvc.fetch(Number(deviceTokenId))
        .subscribe(c => {
          this.device = c
          this.deviceForm = this.getDeviceFormGroup(this.device);

        });
    }
  }

  getDeviceFormGroup(token: DeviceToken): FormGroup {

    if (!token) {
      return this.fb.group({
        deviceTokenId: [''],
        naam: [''],
        passkey: [''],
        tokenStatus: [''],
        geldig: ['']
      });
    } else {

      return this.fb.group({
        deviceTokenId: [token.deviceTokenId],
        naam: [token.naam],
        passkey: [token.passkey],
        tokenStatus: [token.tokenStatus],
        geldig: [token.geldig]
      });

    }
  }

  RecordOpslaan() {

    if (this.deviceForm.touched) {

      this.device.naam = this.deviceForm.value.naam;
      this.device.geldig = this.deviceForm.value.geldig;

      this.deviceTokenSvc.save(this.device).subscribe(c => {

        this.device = c;
        this.deviceForm = this.getDeviceFormGroup(this.device);
      },
        error => {
          this.showErrorToast(error, 'Fout bij het opslaan van het device.');
        })
    }
  }

  RecordVerwijderen() {

    const akkoord = confirm('Record verwijderen?');

    if (akkoord && this.device.deviceTokenId) {
      this.deviceTokenSvc.delete(this.device.deviceTokenId).subscribe(r => {
        this.router.navigate(['devicetokens']);
      },
        error => {
          this.showErrorToast(error, 'Fout bij het verwijderen van het device.');
        });
    }
  }

  Loskoppelen() {

    this.deviceTokenSvc.ontkoppel(Number(this.device.deviceTokenId))
      .subscribe(c => {
        this.device = c
        this.deviceForm = this.getDeviceFormGroup(this.device);
      },
        error => {
          this.showErrorToast(error, 'Fout bij het ontkoppelen van het device.');
        });
  }

  showErrorToast(error: any, melding: string) {

    let tekst = melding;

    if (!tekst && error.message) {
      tekst += '(' + error.message + ')';
    }

    this.snackBar.open(tekst, null, { duration: 3000 });
  }

}
