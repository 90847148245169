<app-toolbar appinfo="appinfo" titel="Beheer Resources">Beheer Resources</app-toolbar>
<div class="content" fxLayout="column">

  <button mat-raised-button (click)="RecordToevoegen()" style="margin: 8px; margin-left:0; width:300px;">Resource toevoegen</button>

  <table mat-table matSort [dataSource]="dataSource">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="Details(row)" class="clickable"></tr>

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Naam</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="resourceType">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.resourceType === 0 ? 'Identity' : 'Api' }}
      </td>
    </ng-container>
  </table>

</div>
