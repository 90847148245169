import { Injectable, OnInit } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,

  HttpErrorResponse
} from '@angular/common/http';
import { AuthService, User } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor( public auth: AuthService, private router: Router ) {
  }

  handleAccessDeniedError( e: HttpErrorResponse ) : Observable<any> {
    if ( e.status === 401 ) {
      this.auth.isLoggedInObs().subscribe( loggedIn => {

        if ( loggedIn ) {
          this.auth.renewToken();
        } else {
          this.auth.login();
        }
        
      } );
    }

    return throwError(e);
  }

  intercept( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {

    return this.auth.getUser().pipe( mergeMap((user: User) => {

      if ( user ) {
        let headers = request.headers
          .set( 'Cache-Control', 'no-cache' )
          .set( 'Pragma', 'no-cache' )
          .set( 'If-Modified-Since', 'Mon, 26 Jul 1997 05:00:00 GMT' )
          .set( 'Authorization', `Bearer ${user.access_token}` );

        request = request.clone( { headers } );
      }

      return next.handle(request).pipe(catchError(e => this.handleAccessDeniedError(e)));
    } ));
  }
}
