import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppUser } from '../models/app-user';
import { AppUserService } from '../services/app-user.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-app-users',
  templateUrl: './app-users.component.html',
  styleUrls: ['./app-users.component.scss']
})
export class AppUsersComponent implements OnInit {

  dataSource = new MatTableDataSource<AppUser>();
  displayedColumns: string[] = ['appUserId', 'name','lastLoginDateTime', 'identityProviderCaption', 'isDisabled', 'isValid'];
  
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  filterForm: FormGroup = this.fb.group({
    Naam: ['']
    
  });

  constructor(
    private appUserSvc: AppUserService,
    private router: Router,
    private fb: FormBuilder) {
  }

  ngOnInit() {
    this.filterGewijzigd();
  }

  public Details(appUser: AppUser) {
    this.router.navigate(['appuser', { appUserId: appUser.appUserId }]);
  }


  filterGewijzigd() {
    
    let gezochteNaam: string = this.filterForm.value.Naam;

    this.appUserSvc.get(gezochteNaam).subscribe(data => {
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
    });
  }
  
}
