<app-toolbar appinfo="appinfo" titel="Beheer Clients">Beheer Clients</app-toolbar>
<div class="content" fxLayout="column">

  <button mat-raised-button (click)="RecordToevoegen()" style="margin: 8px; margin-left:0; width:300px;">Client toevoegen</button>

  <table mat-table matSort [dataSource]="dataSource">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="Details(row)" class="clickable"></tr>

    <ng-container matColumnDef="clientId">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>ClientId</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.clientId }}
      </td>
    </ng-container>
    <ng-container matColumnDef="clientName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Naam</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.clientName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="enabled">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Actief</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.enabled }}
      </td>
    </ng-container>
  </table>

</div>
