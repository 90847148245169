import { Component, OnInit } from '@angular/core';
import { DeviceCodeIdpRp } from '../models/device-code-idp-rp';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceCodeIdpRpService } from '../services/device-code-idp-rp.service';

@Component({
  selector: 'app-device-code-rp',
  templateUrl: './device-code-rp.component.html',
  styleUrls: ['./device-code-rp.component.scss']
})
export class DeviceCodeRpComponent implements OnInit {

  rp: DeviceCodeIdpRp;
  rpForm: FormGroup = this.getRpFormGroup(null);

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private deviceCodeRpSvc: DeviceCodeIdpRpService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    let deviceCodeIdpRpId: string = this.route.snapshot.params.deviceCodeIdpRpId;

    if (!deviceCodeIdpRpId) {

      this.rp = new DeviceCodeIdpRp();

      this.rpForm = this.getRpFormGroup(this.rp);

    } else {
      this.deviceCodeRpSvc.fetch(Number(deviceCodeIdpRpId))
        .subscribe(c => {
          this.rp = c
          this.rpForm = this.getRpFormGroup(this.rp);

        });
    }

  }


  getRpFormGroup(rp: DeviceCodeIdpRp): FormGroup {

    if (!rp) {
      return this.fb.group({
        deviceCodeIdpRpId: [''],
        naam: [''],
        client_Id: [''],
        client_Secret: [''],
        redirectUri: ['']
      });
    } else {

      return this.fb.group({
        deviceCodeIdpRpId: [rp.deviceCodeIdpRpId],
        naam: [rp.naam],
        client_Id: [rp.client_Id],
        client_Secret: [rp.client_Secret],
        redirectUri: [rp.redirectUri]
      });

    }
  }

  RecordOpslaan() {
   

    if (this.rpForm.touched) {

      this.rp.naam = this.rpForm.value.naam;
      this.rp.client_Id = this.rpForm.value.client_Id;
      this.rp.client_Secret = this.rpForm.value.client_Secret;
      this.rp.redirectUri = this.rpForm.value.redirectUri;

      this.deviceCodeRpSvc.save(this.rp).subscribe(c => {

        if (!this.rp.deviceCodeIdpRpId) {
          this.rp.deviceCodeIdpRpId = c.deviceCodeIdpRpId;
        }

        this.rpForm.markAsPristine();
        this.rpForm.markAsUntouched();
      },
        error => {
          this.showErrorToast(error, 'Fout bij het opslaan van de relying party.');
        })

    }

  }

  RecordVerwijderen() {

    var akkoord = confirm('Record verwijderen?');

    if (akkoord && this.rp.deviceCodeIdpRpId) {
      this.deviceCodeRpSvc.delete(this.rp.deviceCodeIdpRpId).subscribe(r => {
        this.router.navigate(['devicecodeidprps']);
      },
        error => {
          this.showErrorToast(error, 'Fout bij het verwijderen van de relying party.');
        });
    }
  }
  showErrorToast(error: any, melding: string) {

    let tekst = melding;

    if (!tekst && error.message) {
      tekst += '(' + error.message + ')';
    }

    this.snackBar.open(tekst, null, { duration: 3000 });
  }
}
