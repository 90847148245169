<app-toolbar appinfo="appinfo" titel="Beheer Settings">Beheer Settings</app-toolbar>
<div class="content" fxLayout="column">

  <mat-card style="margin-top:8px;" fxLayout="column">
    <h3>Settings</h3>

    <table mat-table matSort [dataSource]="dataSource">
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="Details(row)"></tr>

      <ng-container matColumnDef="appSettingId">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Id</th>
        <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
          {{ x.appSettingId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="category">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Categorie</th>
        <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
          {{ x.category }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Naam</th>
        <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
          {{ x.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="appSettingType">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
          {{ x.appSettingType }}
        </td>
      </ng-container>

      <ng-container matColumnDef="appSettingValue">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Waarde</th>
        <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
          {{ x.appSettingValue }}
        </td>
      </ng-container>


    </table>
  </mat-card>
</div>
