import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../models/app-config';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private static appConfig: AppConfig

  constructor() {
    //if ( !AppConfigService.appConfig ) {
    //  this.loadAppConfig().then( function () { return; } )
    //}
  }

  loadAppConfig() {

    const promise = new Promise<void>((resolve, reject) => {

      fetch('/app-config.json')
        .then((response) => response.json())
        .then(function (c) {
          AppConfigService.appConfig = {
            "oidcAuthority": c.oidcAuthority,
            "oidcClientId": c.oidcClientId,
            "oidcRedirectUri": c.oidcRedirectUri,
            "oidcPostLogoutRedirectUri": c.oidcPostLogoutRedirectUri,
            "oidcScopes": c.oidcScopes,
            "oidcSilentRedirectUri": c.oidcSilentRedirectUri,
            "apiRoot": c.apiRoot,
            "version": c.version
          };

          resolve();

        }, function () {
          reject();
        });

    });

    return promise;
  }

  getConfig() {
    return AppConfigService.appConfig;
  }
}
