import { Component, OnInit, ViewChild } from '@angular/core';
import { DeviceCodeIdpRpService } from '../services/device-code-idp-rp.service';
import { DeviceCodeIdpRp } from '../models/device-code-idp-rp';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-device-code-rps',
  templateUrl: './device-code-rps.component.html',
  styleUrls: ['./device-code-rps.component.scss']
})
export class DeviceCodeRpsComponent implements OnInit {

  dataSource = new MatTableDataSource<DeviceCodeIdpRp>();
  displayedColumns: string[] = ['naam', 'clientId', 'redirectUri'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private deviceCodeRpSvc: DeviceCodeIdpRpService,
    private router: Router
  ) { }

  ngOnInit() {
    this.deviceCodeRpSvc.get().subscribe(data => {
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
    });
  }
  public Details(rp: DeviceCodeIdpRp) {
    this.router.navigate(['devicecodeidprp', { deviceCodeIdpRpId: rp.deviceCodeIdpRpId }]);
  }

  public RecordToevoegen() {
    this.router.navigate(['devicecodeidprp']);
  }
}
