import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppInfoService } from './services/app-info.service';
import { AppConfigService } from './services/app-config.service';
import { AuthService } from './services/auth.service';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { User } from 'oidc-client';
import { AppInfo } from './models/app-info';
import * as moment from 'moment';
import 'moment/locale/nl';
import { MenuItem } from './models/menu-item';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    watcher: Subscription;
    activeMediaQuery = "";
    appComponentMenuIsOpen: boolean = true;
    currentUser: User;
    drawerMode: string = "side";
    versionnumber: string = "1.1.0.0";

    appInfo: AppInfo = {
        username: null,
        versionNumber: '1.1.0.0',
        isAdmin: false,
        isGebruiker: false,
        isRegioBeheerder: false,
        menuItems: []
    };


    constructor(private router: Router, private authService: AuthService, media: MediaObserver,
        private appConfigSvc: AppConfigService, private appInfoSvc: AppInfoService) {

        this.watcher = media.media$.subscribe((change: MediaChange) => {
            this.activeMediaQuery = change ? change.mqAlias : '';
            if (change.mqAlias == 'xs' || change.mqAlias == 'sm') {
                this.appComponentMenuIsOpen = false;
                this.drawerMode = "over";
            } else {
                this.appComponentMenuIsOpen = true;
                this.drawerMode = "side";
            }
        });
    }

    ngOnInit() {

        moment.locale('nl');

        this.authService.getUser().subscribe(u => {
            this.currentUser = u;
            this.appInfoSvc.get().subscribe(a => {
                this.appInfo = a;
                this.versionnumber = a.versionNumber;
            });
        });
    }

    login() {
        this.authService.login();
    }

    public DrawerMode(): string {
        return this.drawerMode;
    }

    public navigate(pad: string) {

        if (this.activeMediaQuery == 'xs' || this.activeMediaQuery == 'sm') {
            this.appComponentMenuIsOpen = false;
        }

        this.router.navigateByUrl(pad);
    }

    public naam(): string {

        if (!!this.currentUser && !!this.currentUser.profile && !!this.currentUser.profile['name']) {
            return this.currentUser.profile['name'];
        } else {
            return 'Onbekend';
        }
    }

    public menuIsOpen(): boolean {

        return this.appComponentMenuIsOpen;
    }

    public toggleMenu() {
        this.appComponentMenuIsOpen = !this.appComponentMenuIsOpen;
    }

    public toggleChildren(item: MenuItem) {
        item.childrenVisible = !item.childrenVisible;
    }
}
