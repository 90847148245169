import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { AppSettingService } from '../services/app-setting.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppSetting } from '../models/app-setting';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-beheer-settings',
  templateUrl: './beheer-settings.component.html',
  styleUrls: ['./beheer-settings.component.scss']
})
export class BeheerSettingsComponent implements OnInit {

  dataSource = new MatTableDataSource<AppSetting>();
  displayedColumns: string[] = ['appSettingId', 'category', 'name', 'appSettingValue'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private appSettingSvc: AppSettingService, private dialog: MatDialog) { }

  ngOnInit() {
    this.ladenSettings();
  }

  ladenSettings() {
    this.appSettingSvc.load().subscribe(a => {
      this.dataSource.data = a;
      this.dataSource.sort = this.sort;
    });
  }

  Details(appSetting: AppSetting) {

    let dialogRef = this.dialog.open(AppSettingDialog, { data: appSetting });

    dialogRef.afterClosed().subscribe(r => {
      this.ladenSettings();
    });
  }

}


@Component({
  selector: 'app-setting-dialog',
  templateUrl: 'app-setting-dialog.html',
})
export class AppSettingDialog {

  appSettingForm: FormGroup = this.fb.group({
    voertuigSoortCode: [],
    naam: []
  });


  constructor(@Inject(MAT_DIALOG_DATA) public data: AppSetting,
    public dialogRef: MatDialogRef<AppSettingDialog>,
    private fb: FormBuilder,
    private appSettingSvc: AppSettingService,
    private snackBar: MatSnackBar

  ) {

    this.appSettingForm = this.fb.group({
      category: [data.category],
      name: [data.name],
      appSettingValue: [data.appSettingValue]
    });

  }

  SluitDialog() {

    this.data.appSettingValue = this.appSettingForm.controls['appSettingValue'].value;

    this.appSettingSvc.save(this.data).subscribe(r => {
      this.dialogRef.close();
    },
      error => {
        this.showErrorToast(error, 'Fout bij het opslaan');
      });
  }

  CancelDialog() {
    this.dialogRef.close();
  }

  showErrorToast(error: any, melding: string) {

    let tekst = melding;

    if (error.message) {
      tekst += '(' + error.message + ')';
    }

    this.snackBar.open(tekst, null, { duration: 3000 });
  }
}
