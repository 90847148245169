export class Client {
  constructor() {
    this.allowedScopes = '';
    this.allowedRedirectUris = '';
    this.allowedCorsOrigins = '';
  }

  clientId: string;
  absoluteRefreshTokenLifetime: number;
  accessTokenLifetime: number;
  accessTokenType: number;
  allowedCorsOrigins: string;
  allowedGrantTypes: string;
  allowedScopes: string;
  allowRememberConsent: boolean;
  alwaysSendClientClaims: boolean;
  authorizationCodeLifetime: number;
  clientName: string;
  clientUri: string;
  enabled: boolean;
  enableLocalLogin: boolean;
  identityTokenLifetime: number;
  includeJwtId: boolean;
  logoUri: string;
  postLogoutRedirectUris: string;
  prefixClientClaims: boolean;
  refreshTokenExpiration: number;
  refreshTokenUsage: number;
  requireConsent: boolean;
  slidingRefreshTokenLifetime: number;
  updateAccessTokenClaimsOnRefresh: boolean;
  allowedRedirectUris: string;
  alwaysIncludeUserClaimsInIdToken: boolean;
  allowOfflineAccess: boolean;
  identityProviderRestrictions: string;
  userSsoLifetime: number;
  userCodeType: string;
  deviceCodeLifeTime: number;
  protocolType: string;
  requireClientSecret: boolean;
  description: string;
  requirePkce: boolean;
  allowPlainTextPkce: boolean;
  allowAccessTokensViaBrowser: boolean;
  frontChannelLogoutUri: string;
  frontChannelLogoutSessionRequired: boolean;
  backChannelLogoutUri: string;
}
