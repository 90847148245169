<app-toolbar appinfo="appinfo" titel="Identity Beheer"></app-toolbar>

<div class="content">
  <div *ngIf="!isIngelogd()">
    <div>
      Voor het gebruik van deze applicatie dient u in te loggen.
    </div>
    <div>
      <br />
      <button mat-button mat-button-primary mat-raised-button (click)="login()">Inloggen</button>
    </div>
  </div>
  <div *ngIf="isIngelogd()">
    <div>
      U bent ingelogd als {{ naam() }}
    </div>
    <div>
      <br />
      <button mat-button mat-button-primary mat-raised-button (click)="logout()">Uitloggen</button>
    </div>

  </div>
</div>


