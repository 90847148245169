import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { AppSetting } from '../models/app-setting';

@Injectable({
  providedIn: 'root'
})
export class AppSettingService {

  constructor(private http: HttpClient, private appConfigSvc: AppConfigService) { }

  load() {
    return this.http.get<AppSetting[]>(this.appConfigSvc.getConfig().apiRoot + '/AppSetting');
  }

  save(appSetting: AppSetting) {
    return this.http.put<AppSetting>(this.appConfigSvc.getConfig().apiRoot + '/AppSetting/' + appSetting.appSettingId, appSetting);
  }

}
