<mat-sidenav-container class="menucontainer" fxLayout="column" role="navigation">
  <mat-sidenav #drawer [mode]="DrawerMode()" [opened]="menuIsOpen()" class="menudrawer" fxLayout="column">

    <img src="../../assets/menuheader.png" />


    <div *ngFor="let mi of appInfo.menuItems">

      <div class="menubuttonrow" *ngIf="!mi.children">
        <button mat-button class="menubutton" (click)="navigate(mi.componentRoute)">
          {{ mi.titel }}
        </button>
      </div>

      <div *ngIf="mi.children">

        <div class="menubuttonrow">
          <button mat-button class="menubutton" (click)="toggleChildren(mi)">
            {{ mi.titel }}<mat-icon *ngIf="!mi.childrenVisible">expand_less</mat-icon> <mat-icon *ngIf="mi.childrenVisible">expand_more</mat-icon>
          </button>

        </div>

        <div *ngIf="mi.childrenVisible">
          <div *ngFor="let c of mi.children" class="menubuttonrow menubuttonrowsub">
            <button mat-button class="menubutton" (click)="navigate(c.componentRoute)">
              {{ c.titel }}
            </button>
          </div>
        </div>
      </div>


    </div>

    <div fxLayout="column" fxFlex></div>
    <div class="menufooter">Versie {{ versionnumber }} <br />Ingelogd als {{ naam() }}</div>
  </mat-sidenav>
  <mat-sidenav-content>

    <router-outlet></router-outlet>

  </mat-sidenav-content>
</mat-sidenav-container>

