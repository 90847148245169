import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ClientService } from '../services/client.service';
import { Client } from '../models/client';


@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  dataSource = new MatTableDataSource<Client>();
  displayedColumns: string[] = ['clientId', 'clientName', 'enabled'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;


  constructor(private clientSvc: ClientService, private router: Router) { }

  ngOnInit() {
    this.clientSvc.get().subscribe(data => {
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
    });

  }

  public Details(client: Client) {
    this.router.navigate(['client', { clientId: client.clientId }]);
  }

  public RecordToevoegen() {
    this.router.navigate(['client']);
  }

}
