<app-toolbar appinfo="appinfo" titel="Beheer Gebruikers">Beheer Gebruikers</app-toolbar>
<div class="content" fxLayout="column">


  <div>
    <form [formGroup]="filterForm">
      <mat-form-field>
        <mat-icon class="clickable" (click)="filterGewijzigd()" matSuffix>search</mat-icon>
        <input type="text" placeholder="Naam" aria-label="Naam" matInput
               formControlName="Naam" (change)="filterGewijzigd()">
      </mat-form-field>

    </form>

  </div>

  <table mat-table matSort [dataSource]="dataSource">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="Details(row)" class="clickable"></tr>

    <ng-container matColumnDef="appUserId">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.appUserId }}
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Naam</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="lastLoginDateTime">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Laatste login</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.lastLoginDateTime | amDateFormat:'YYYY-MM-DD HH:mm:ss' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="identityProviderCaption">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Idp</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.identityProviderCaption }}
      </td>
    </ng-container>
    <ng-container matColumnDef="isDisabled">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Disabled</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.isDisabled }}
      </td>
    </ng-container>
    <ng-container matColumnDef="isValid">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Geldig</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ (x.isValid) }}
      </td>
    </ng-container>
  </table>

</div>
