import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DeviceToken } from '../models/device-token';
import { DeviceTokenService } from '../services/device-token.service';
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-device-tokens',
  templateUrl: './device-tokens.component.html',
  styleUrls: ['./device-tokens.component.scss']
})
export class DeviceTokensComponent implements OnInit {

  dataSource = new MatTableDataSource<DeviceToken>();
  displayedColumns: string[] = ['deviceTokenId','naam', 'passkey', 'geldig'];
  filterForm: FormGroup = this.fb.group({ Naam: [''] });


  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private deviceTokenSvc: DeviceTokenService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.filterGewijzigd();
    this.deviceTokenSvc.get(null).subscribe(data => {
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
      this.dataSource.sortData = (datas: DeviceToken[], sort: MatSort) => {
        return datas.sort((a : DeviceToken, b: DeviceToken) => {
          const isAsc = sort.direction === 'asc';
          switch (sort.active) {
            case 'deviceTokenId': return this.compareN(a.deviceTokenId, b.deviceTokenId, isAsc);
            case 'naam': return this.compareS(a.naam, b.naam, isAsc);
            case 'tokenStatus': return this.compareS(a.tokenStatus, b.tokenStatus, isAsc);
            case 'geldig': return this.compareS(String(a.geldig), String(b.geldig), isAsc);
            case 'passkey': return this.compareS(a.passkey, b.passkey, isAsc);
            default: return 0;
          }
        });
      };
    });
  }

  compareN(a: number, b: number, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareS(a: string, b: string, isAsc: boolean) {
    return (a.toLowerCase() < b.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public Details(t: DeviceToken) {
    this.router.navigate(['devicetoken', { deviceTokenId: t.deviceTokenId }]);
  }

  public RecordToevoegen() {
    this.router.navigate(['devicetoken']);
  }

  filterGewijzigd() {
    const gezochteNaam: string = this.filterForm.value.Naam;

    this.deviceTokenSvc.get(gezochteNaam).subscribe(data => {
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
    });
  }

  compareString(a: string, b: string, isAsc: boolean) {
    return a.localeCompare(b, 'NL-nl') * (isAsc ? 1 : -1);
  }

}
