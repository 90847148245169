import { Component, OnInit, ViewChild } from '@angular/core';
import { ResourceService } from '../services/resource.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, AbstractControl } from '@angular/forms';
import { Client } from '../models/client';
import { ClientService } from '../services/client.service';
import { Observable } from 'rxjs';
import { map, pluck, concatMap, flatMap, toArray } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClientSecret } from '../models/client-secret';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {

  client: Client;
  clientForm: FormGroup = this.getClientFormGroup(null);
  allScopes: string[] = [];

  clientSecrets: ClientSecret[];

  clientSecretsForm: FormGroup = this.getClientSecretFormGroup(null);
  nieuwSecretForm: FormGroup = this.getnieuwSecretFormGroup();

  @ViewChild('nieuweUri', { static: true }) nieuweUriInput;
  @ViewChild('nieuwePostLogoutUri', { static: true }) nieuwePostLogoutUriInput;
  @ViewChild('nieuweOrigin', { static: true }) nieuweOriginInput;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private clientSvc: ClientService,
    private resourceSvc: ResourceService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {

    const clientId: string = this.route.snapshot.params.clientId;
    
    if (!clientId) {

      this.client = new Client();

      this.getAllScopes().subscribe(
        r => {
          this.allScopes = r;
          this.clientForm = this.getClientFormGroup(this.client);
        }
      )
    } else {
      this.clientSvc.fetch(this.route.snapshot.params.clientId)
        .subscribe(c => {
          
          this.getAllScopes().subscribe(
            r => {
              this.allScopes = r;
              this.client = c
              this.clientForm = this.getClientFormGroup(this.client);

              this.clientSvc.getSecrets(c.clientId).subscribe(s => {
                this.clientSecrets = s;
                this.clientSecretsForm = this.getClientSecretFormGroup(s);
              });
            }
          )

        });
    }

  }

  getAllScopes(): Observable<string[]> {

    return this.resourceSvc.get().pipe(
      flatMap(x => x),
      concatMap(y => y.scopes),
      pluck('name'),
      map(z => z as string),
      toArray()
    );
  }

  getClientFormGroup(client: Client): FormGroup {

    if (!client) {
      return this.fb.group({
        clientId: [''],
        clientName: [''],
        enabled: [''],
        accessTokenLifetime: [''],
        allowedGrantTypes: [''],
        allowedScopes: [''],
        allowedRedirectUris: [''],
        allowAccessTokensViaBrowser: [''],
        allowedCorsOrigins: [''],
        absoluteRefreshTokenLifetime: ['2592000'],
        slidingRefreshTokenLifetime: ['1296000'],
        identityTokenLifetime: ['86000'],
        refreshTokenExpiration: [0],
        refreshTokenUsage: [1],
        updateAccessTokenClaimsOnRefresh: [true],
        allowOfflineAccess: [false],
        requirePkce: [true],
        postLogoutRedirectUris: [''],

        
      });
    } else {

      return this.fb.group({
        clientId: [client.clientId],
        clientName: [client.clientName],
        enabled: [client.enabled],
        accessTokenLifetime: [client.accessTokenLifetime],
        allowedGrantTypes: [client.allowedGrantTypes],
        allowedScopes: [client.allowedScopes.split('|')],
        allowAccessTokensViaBrowser: [client.allowAccessTokensViaBrowser],
        allowedRedirectUris: this.getRedirectUriArray(client.allowedRedirectUris.split('|')),
        allowedCorsOrigins: this.getCorsOriginArray(client.allowedCorsOrigins.split('|')),
        absoluteRefreshTokenLifetime: [client.absoluteRefreshTokenLifetime],
        slidingRefreshTokenLifetime: [client.slidingRefreshTokenLifetime],
        identityTokenLifetime: [client.identityTokenLifetime],
        refreshTokenExpiration: [client.refreshTokenExpiration],
        refreshTokenUsage: [client.refreshTokenUsage],
        updateAccessTokenClaimsOnRefresh: [client.updateAccessTokenClaimsOnRefresh],
        allowOfflineAccess: [client.allowOfflineAccess],
        requirePkce: [client.requirePkce],
        postLogoutRedirectUris: this.getPostLogoutRedirectUriArray(client.postLogoutRedirectUris.split('|')),

      });

    }
  }

  getRedirectUriArray(uris: string[]): FormArray {

    const a = this.fb.array([]);

    uris.forEach(u => {
      a.push(this.fb.group({ allowedRedirectUri: u }));
    });
    return a;
  }

  RedirectUriVerwijderen(e) {
    console.log(e);

    const uriArray: FormArray = this.clientForm.get('allowedRedirectUris') as FormArray;
    const idx = uriArray.controls.indexOf(e);

    if (idx !== -1) {
      uriArray.removeAt(idx);
      this.clientForm.markAsDirty();
      this.clientForm.markAsTouched();
    }

  }

  nieuweUriChanged() {

    const uriArray: FormArray = this.clientForm.get('allowedRedirectUris') as FormArray;
    uriArray.push(this.fb.group({ allowedRedirectUri: this.nieuweUriInput.nativeElement.value }));

    this.nieuweUriInput.nativeElement.value = '';
    this.clientForm.markAsDirty();
    this.clientForm.markAsTouched();
  }


  getPostLogoutRedirectUriArray(uris: string[]): FormArray {

    const a = this.fb.array([]);

    uris.forEach(u => {
      a.push(this.fb.group({ postLogoutRedirectUri: u }));
    });
    return a;
  }

  PostLogoutRedirectUriVerwijderen(e) {

    const uriArray: FormArray = this.clientForm.get('postLogoutRedirectUris') as FormArray;
    const idx = uriArray.controls.indexOf(e);

    if (idx !== -1) {
      uriArray.removeAt(idx);
      this.clientForm.markAsDirty();
      this.clientForm.markAsTouched();
    }

  }

  nieuwePostLogoutUriChanged() {

    const uriArray: FormArray = this.clientForm.get('postLogoutRedirectUris') as FormArray;
    uriArray.push(this.fb.group({ allowedPostLogoutRedirectUri: this.nieuwePostLogoutUriInput.nativeElement.value }));

    this.nieuwePostLogoutUriInput.nativeElement.value = '';
    this.clientForm.markAsDirty();
    this.clientForm.markAsTouched();
  }


  getCorsOriginArray(origins: string[]): FormArray {

    const a = this.fb.array([]);

    origins.forEach(u => {
      a.push(this.fb.group({ allowedCorsOrigin: u }));
    });
    return a;
  }

  CorsOriginVerwijderen(e) {

    const originArray: FormArray = this.clientForm.get('allowedCorsOrigins') as FormArray;
    const idx = originArray.controls.indexOf(e);

    if (idx !== -1) {
      originArray.removeAt(idx);
      this.clientForm.markAsDirty();
      this.clientForm.markAsTouched();
    }

  }

  nieuweOriginChanged() {

    const originArray: FormArray = this.clientForm.get('allowedCorsOrigins') as FormArray;
    originArray.push(this.fb.group({ allowedCorsOrigin: this.nieuweOriginInput.nativeElement.value }));

    this.nieuweOriginInput.nativeElement.value = '';
    this.clientForm.markAsDirty();
    this.clientForm.markAsTouched();
  }

  RecordOpslaan() {
    console.log(this.clientForm);


    if (this.clientForm.touched) {

      if (!this.client.clientId) {
        this.client.clientId = this.clientForm.value.clientId;
      }

      this.client.clientName = this.clientForm.value.clientName;
      this.client.enabled = this.clientForm.value.enabled;
      this.client.allowAccessTokensViaBrowser = this.clientForm.value.allowAccessTokensViaBrowser;
      this.client.allowedGrantTypes = this.clientForm.value.allowedGrantTypes;
      this.client.accessTokenLifetime = this.clientForm.value.accessTokenLifetime;
      this.client.allowedScopes = (this.clientForm.value.allowedScopes as string[]).join('|');

      this.client.requirePkce = this.clientForm.value.requirePkce;
      this.client.allowOfflineAccess = this.clientForm.value.allowOfflineAccess;
      this.client.updateAccessTokenClaimsOnRefresh = this.clientForm.value.updateAccessTokenClaimsOnRefresh;
      this.client.refreshTokenUsage = this.clientForm.value.refreshTokenUsage;
      this.client.refreshTokenExpiration = this.clientForm.value.refreshTokenExpiration;
      this.client.identityTokenLifetime = this.clientForm.value.identityTokenLifetime;
      this.client.slidingRefreshTokenLifetime = this.clientForm.value.slidingRefreshTokenLifetime;
      this.client.absoluteRefreshTokenLifetime = this.clientForm.value.absoluteRefreshTokenLifetime;

      const uris: string[] = [];
      (this.clientForm.value.allowedRedirectUris as object[]).forEach(a => uris.push(a['allowedRedirectUri']));
      this.client.allowedRedirectUris = uris.join('|');

      const postLogoutUris: string[] = [];
      (this.clientForm.value.postLogoutRedirectUris as object[]).forEach(a => postLogoutUris.push(a['postLogoutRedirectUri']));
      this.client.postLogoutRedirectUris = postLogoutUris.join('|');

      const origins: string[] = [];
      (this.clientForm.value.allowedCorsOrigins as object[]).forEach(a => origins.push(a['allowedCorsOrigin']));
      this.client.allowedCorsOrigins = origins.join('|');

      this.clientSvc.save(this.client).subscribe(c => {
        console.log(c);
        this.clientForm.markAsPristine();
        this.clientForm.markAsUntouched();
      },
        error => {
          this.showErrorToast(error, 'Fout bij het opslaan van de client');
        })

    }

  }

  getClientSecretFormGroup(clientSecrets: ClientSecret[]): FormGroup {

    const secretControls = this.fb.array([]);

    if (clientSecrets) {
      clientSecrets.forEach(c => {
        secretControls.push(this.fb.group({
          clientSecretId: [c.clientSecretId],
          description: [c.description],
          value: [c.value],
          validFrom: [c.validFrom],
          validUntil: [c.validUntil]
        }));
      });
    }

    return this.fb.group({
      secrets: secretControls
    });
  }

  getnieuwSecretFormGroup(): FormGroup {

    return this.fb.group({
      clientSecretId: [0],
      description: [''],
      value: [''],
      validFrom: ['2000-01-01'],
      validUntil: ['2100-01-01']
    });
  }

  SecretsOpslaan() {
    console.log(this.clientSecretsForm);

    if (this.clientSecretsForm.touched) {

      const secretsArray = this.clientSecretsForm.controls['secrets'] as FormArray;
      secretsArray.controls.forEach(c => {
        if (c.touched) {

          const cs: ClientSecret = {
            clientSecretId: c.value.clientSecretId,
            clientId: c.value.clientId,
            description: c.value.description,
            value: c.value.value,
            validFrom: c.value.validFrom,
            validUntil: c.value.validUntil
          };

          this.clientSvc.saveSecret(cs).subscribe(s => {
            console.log(s);
          });
          
        }
      });

      // check verwijderde secrets

      const bestaandeSecrets: ClientSecret[] = secretsArray.value as ClientSecret[];
      this.clientSecrets.forEach(s => {
        
        if (bestaandeSecrets.findIndex(x => x.clientSecretId === s.clientSecretId) === -1) {
          this.clientSvc.deleteSecret(s).subscribe(d => {
            console.log(d);
          });
        }
      });

      this.clientSecretsForm.markAsPristine();
      this.clientSecretsForm.markAsUntouched();
    }
  }

  SecretToevoegen() {
    
    const secretsArray = this.clientSecretsForm.controls['secrets'] as FormArray;

    const nieuwSecret = this.fb.group({
      clientId: this.client.clientId,
      clientSecretId: [this.nieuwSecretForm.value.clientSecretId],
      description: [this.nieuwSecretForm.value.description],
      value: [this.nieuwSecretForm.value.value],
      validFrom: [this.nieuwSecretForm.value.validFrom],
      validUntil: [this.nieuwSecretForm.value.validUntil]
    });

    nieuwSecret.markAsTouched();
    nieuwSecret.markAsDirty();

    secretsArray.controls.push(nieuwSecret);

    this.clientSecretsForm.markAsDirty();
    this.clientSecretsForm.markAsTouched();

    this.nieuwSecretForm = this.getnieuwSecretFormGroup();
  }

  SecretVerwijderen(c: AbstractControl) {

    const secretsArray = this.clientSecretsForm.controls['secrets'] as FormArray;
    const idx = secretsArray.controls.findIndex(x => x === c);

    if (idx !== -1) {
      secretsArray.removeAt(idx);
      this.clientSecretsForm.markAsDirty();
      this.clientSecretsForm.markAsTouched();
    }
  }

  showErrorToast(error: any, melding: string) {

    let tekst = melding;

    if (!tekst && error.message) {
      tekst += '(' + error.message + ')';
    }

    this.snackBar.open(tekst, null, { duration: 3000 });
  }
}
