<app-toolbar appinfo="appinfo" titel="Beheer Device"></app-toolbar>

<div class="content" fxLayout="column">

  <mat-card style="margin-top:8px;">
    <div fxLayout="row">
      <button mat-raised-button color="primary" (click)="RecordOpslaan()" [disabled]="!deviceForm.dirty" style="margin: 8px;">Opslaan</button>
      <mat-icon class="dirtyIcon" *ngIf="deviceForm.dirty">edit</mat-icon>

      <button mat-raised-button color="warn" (click)="RecordVerwijderen()" [disabled]="deviceForm.dirty || !device.deviceTokenId" style="margin: 8px;">Verwijderen</button>

      <button mat-raised-button color="Info" (click)="Loskoppelen()" [disabled]="!device.deviceTokenId || (device.tokenStatus === 'Vrij')" style="margin: 8px;">Loskoppelen</button>

    </div>

    <div style="padding: 8px;">
      <form [formGroup]="deviceForm">
        <div fxLayout="column wrap">

          <mat-form-field>
            <input matInput formControlName="deviceTokenId" placeholder="Id" readonly disabled>
          </mat-form-field>
          <mat-form-field>
            <input #naamInput matInput formControlName="naam" placeholder="Naam" required>
          </mat-form-field>
          
          <mat-slide-toggle matInput formControlName="geldig">Geldig</mat-slide-toggle>
          

          <mat-form-field style="margin-top:20px;" *ngIf="device.deviceTokenId">
            <input matInput formControlName="passkey" placeholder="Toegangscode" required readonly>
          </mat-form-field>
          <mat-form-field *ngIf="device.deviceTokenId">
            <input matInput formControlName="tokenStatus" placeholder="Status" readonly>
          </mat-form-field>

        </div>
      </form>
    </div>
  </mat-card>


</div>
