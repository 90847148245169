import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DeviceToken } from '../models/device-token';

@Injectable({
  providedIn: 'root'
})
export class DeviceTokenService {

  constructor(private http: HttpClient, private appConfigSvc: AppConfigService) { }

  get(naam: string) {
    const params = new HttpParams()
      .append('naam', naam ? naam : '');
    return this.http.get<DeviceToken[]>(this.appConfigSvc.getConfig().apiRoot + '/DeviceToken',  { params: params });
  }

  fetch(deviceTokenId: number) {
    return this.http.get<DeviceToken>(this.appConfigSvc.getConfig().apiRoot + '/DeviceToken/' + deviceTokenId);
  }

  ontkoppel(deviceTokenId: number) {
    return this.http.get<DeviceToken>(this.appConfigSvc.getConfig().apiRoot + '/DeviceToken/' + deviceTokenId + '/ontkoppel');
  }

  save(token: DeviceToken) {
    if (token.deviceTokenId) {
      return this.http.put<DeviceToken>(this.appConfigSvc.getConfig().apiRoot + '/DeviceToken/' + token.deviceTokenId, token);
    } else {
      return this.http.post<DeviceToken>(this.appConfigSvc.getConfig().apiRoot + '/DeviceToken', token);
    }
  }

  delete(deviceTokenId: number) {
    return this.http.delete(this.appConfigSvc.getConfig().apiRoot + '/DeviceToken/' + deviceTokenId);
  }

}
