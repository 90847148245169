<h2 mat-dialog-title>Setting</h2>
<mat-dialog-content class="mat-typography">
  <div style="padding: 8px;">
    <form [formGroup]="appSettingForm">
      <div fxLayout="column wrap">

        <mat-form-field>
          <input matInput formControlName="category" placeholder="Categorie" readonly>
        </mat-form-field>
        <mat-form-field>
          <input matInput formControlName="name" placeholder="Naam" readonly>
        </mat-form-field>
        <mat-form-field>
          <input matInput formControlName="appSettingValue" placeholder="Waarde">
        </mat-form-field>
      
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="SluitDialog()" cdkFocusInitial>Bijwerken</button>
  <button mat-button (click)="CancelDialog()">Annuleren</button>
</mat-dialog-actions>
