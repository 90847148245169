<app-toolbar appinfo="appinfo" titel="Beheer gebruiker">Beheer gebruiker</app-toolbar>

<div class="content" fxLayout="column">

  <mat-card style="margin-top:8px;">
    <h3>{{ appUser.name}}</h3>

    <form [formGroup]="appUserForm">


      <div fxLayout="column wrap">


        <mat-form-field>
          <input matInput value="{{appUser.appUserId}}" placeholder="AppUserId" disabled>
        </mat-form-field>

        <mat-form-field>
          <input matInput value="{{appUser.name}}" placeholder="Naam" disabled>
        </mat-form-field>
        <mat-form-field>
          <input matInput value="{{appUser.identityProviderCaption}}" placeholder="Idp" disabled>
        </mat-form-field>
        <mat-form-field>
          <input matInput value="{{appUser.identityProviderSubClaimValue}}" placeholder="Idp Id" disabled>
        </mat-form-field>
        <mat-form-field>
          <input matInput value="{{appUser.emailAddress}}" placeholder="E-mail" disabled>
        </mat-form-field>


      </div>
    </form>

  </mat-card>


  <mat-accordion class="appuseraccordion">
    <mat-expansion-panel class="appuserpanel" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="panelheader">Algemeen</mat-panel-title>
      </mat-expansion-panel-header>

      <form [formGroup]="appUserForm">

        <div fxLayout="column wrap">



          <mat-form-field>
            <input matInput formControlName="phoneNumber" placeholder="Telefoon">
          </mat-form-field>
          <mat-form-field>
            <mat-select formControlName="isDisabled" placeholder="Is gebruiker actief">
              <mat-option [value]="true">Uitgeschakeld</mat-option>
              <mat-option [value]="false">Actief</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <textarea matInput formControlName="remarks" placeholder="Opmerkingen"></textarea>
          </mat-form-field>


          <mat-form-field style="margin-right: 5px;">
            <input matInput [matDatepicker]="pickerVan" placeholder="Geldig Van" formControlName="validFrom">
            <mat-datepicker-toggle matSuffix [for]="pickerVan"></mat-datepicker-toggle>
            <mat-datepicker #pickerVan></mat-datepicker>
          </mat-form-field>
          <mat-form-field style="margin-right: 5px;">
            <input matInput [matDatepicker]="pickerTot" placeholder="Geldig Tot" formControlName="validUntil">
            <mat-datepicker-toggle matSuffix [for]="pickerTot"></mat-datepicker-toggle>
            <mat-datepicker #pickerTot></mat-datepicker>
          </mat-form-field>

        </div>
        <div fxLayout="row">
          <button mat-raised-button color="primary" [disabled]="!appUserForm.dirty" (click)="opslaanAlgemeen()">Opslaan</button>

          <mat-icon *ngIf="appUserForm.dirty">edit</mat-icon>
        </div>

      </form>
    </mat-expansion-panel>

    <mat-expansion-panel class="appuserpanel">
      <mat-expansion-panel-header>
        <mat-panel-title class="panelheader">Rollen</mat-panel-title>
      </mat-expansion-panel-header>

      <form [formGroup]="claimsForm">

        <div>
          <table>
            <thead>
              <tr>
                <th style="text-align:left;">Scope</th>
                <th>Actief</th>
                <th>Van</th>
                <th>Tot</th>
              </tr>
            </thead>

            <tbody formArrayName="items">
              <tr *ngFor="let c of claimsForm.get('items')['controls']; let i = index;">
                <!--<div [formGroupName]="i">-->
                <ng-container formGroupName="{{i}}">
                  <td>
                    {{ c.value.outboundClaimValue }}
                  </td>
                  <td style="padding-left:40px; padding-right:40px;">
                    <mat-slide-toggle formControlName="enabled"></mat-slide-toggle>

                  </td>
                  <td>

                    <mat-form-field>
                      <input matInput [matDatepicker]="claimValidFrom" formControlName="validFrom">
                      <mat-datepicker-toggle matSuffix [for]="claimValidFrom"></mat-datepicker-toggle>
                      <mat-datepicker #claimValidFrom></mat-datepicker>
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field>
                      <input matInput [matDatepicker]="claimValidTo" formControlName="validTo">
                      <mat-datepicker-toggle matSuffix [for]="claimValidTo"></mat-datepicker-toggle>
                      <mat-datepicker #claimValidTo></mat-datepicker>
                    </mat-form-field>
                  </td>

                </ng-container>
              </tr>

            </tbody>
          </table>


        </div>

        <div fxLayout="row">
          <button mat-raised-button color="primary" [disabled]="!claimsForm.dirty" (click)="opslaanClaims()">Opslaan</button>
          <mat-icon *ngIf="claimsForm.dirty">edit</mat-icon>
        </div>
      </form>
    </mat-expansion-panel>

    <mat-expansion-panel class="appuserpanel">
      <mat-expansion-panel-header>
        <mat-panel-title class="panelheader">Logboek</mat-panel-title>
      </mat-expansion-panel-header>
      <table mat-table #sortLogboek="matSort" matSort [dataSource]="dataSourceLogboek">
        <tr mat-header-row *matHeaderRowDef="displayedColumnsLogboek"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsLogboek;"></tr>

        <ng-container matColumnDef="lastLoginDateTime">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Laatste login</th>
          <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
            {{ x.lastLoginDateTime | amDateFormat:'YYYY-MM-DD HH:mm:ss' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="lastLoginIpAddress">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>IP Adres</th>
          <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
            {{ x.lastLoginIpAddress }}
          </td>
        </ng-container>
        <ng-container matColumnDef="lastLoginUserAgent">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>UserAgent</th>
          <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
            {{ x.lastLoginUserAgent }}
          </td>
        </ng-container>
        <ng-container matColumnDef="isDisabled">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Disabled</th>
          <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
            {{ (x.isDisabled) }}
          </td>
        </ng-container>
      </table>
    </mat-expansion-panel>


    <mat-expansion-panel class="appuserpanel">
      <mat-expansion-panel-header>
        <mat-panel-title class="panelheader">Grants</mat-panel-title>
      </mat-expansion-panel-header>
      <table mat-table #sortGrants="matSort" matSort [dataSource]="dataSourceGrants">
        <tr mat-header-row *matHeaderRowDef="displayedColumnsGrants"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsGrants;"></tr>


        <ng-container matColumnDef="persistedGrantType">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
            {{ x.persistedGrantType }}
          </td>
        </ng-container>
        <ng-container matColumnDef="clientId">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>ClientId</th>
          <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
            {{ x.clientId }}
          </td>
        </ng-container>

        <ng-container matColumnDef="creationTime">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Aangemaakt</th>
          <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
            {{ x.creationTime | amDateFormat:'YYYY-MM-DD HH:mm:ss' }}
          </td>
        </ng-container>


        <ng-container matColumnDef="expiration">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Verloopt</th>
          <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
            {{ x.expiration | amDateFormat:'YYYY-MM-DD HH:mm:ss' }}
          </td>
        </ng-container>

      </table>

    </mat-expansion-panel>
  </mat-accordion>

</div>
