import { Component, OnInit } from '@angular/core';
import { ResourceService } from '../services/resource.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { Resource } from '../models/resource';
import { Scope } from '../models/scope';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppUser } from '../models/app-user';
import { AppUserService } from '../services/app-user.service';

@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss']
})
export class ResourceComponent implements OnInit {

  resource: Resource;
  resourceForm: FormGroup = this.getFormGroup(null);
  newScopeForm: FormGroup = this.getNewScopeForm();

  beheerders: AppUser[] = [];
  zoekTerm: FormControl = new FormControl();
  zoekTermTreffers: AppUser[] = [];
  
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private resourceSvc: ResourceService,
    private appUserSvc: AppUserService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {

    let resourceId: number = +this.route.snapshot.params.resourceId;

    if (!resourceId) {

      this.resource = {
        resourceId: 0,
        name: '',
        resourceType: 1,
        resourceSecret: '',
        scopes: []
      };
      this.resourceForm = this.getFormGroup(this.resource);

    } else {
      
      this.resourceSvc.fetch(resourceId)
        .subscribe((r: Resource) => {
          this.resource = r;
          this.resourceForm = this.getFormGroup(this.resource);
          this.resourceSvc.getBeheerders(r.resourceId).subscribe(b => {
            this.beheerders = b;
          });
        });

    }
  }


  getFormGroup(res: Resource): FormGroup {

    if (!res) {
      return this.fb.group({
        resourceId: [''],
        name: [''],
        resourceType: [''],
        resourceSecret: [''],
        scopes: this.fb.array([])
      });
    } else {

      this.getScopesArray(res.scopes);

      return this.fb.group({
        resourceId: [res.resourceId],
        name: [res.name],
        resourceType: [res.resourceType],
        resourceSecret: [res.resourceSecret],
        scopes: this.getScopesArray(res.scopes)
      });
    }
  }

  getScopesArray(scopes: Scope[]): FormArray {

    var a = this.fb.array([]);

    scopes.forEach(u => {
      a.push(this.fb.group({
        scopeId: [u.scopeId],
        description: [u.description],
        scopeType: [u.scopeType],
        name: [u.name]
      }));
    });
    return a;
  }

  getNewScopeForm(): FormGroup {
    return this.fb.group({
      scopeId: [0],
      description: [''],
      scopeType: [1],
      name: ['']
    });
  }

  RecordOpslaan() {
    console.log(this.resourceForm);

    if (this.resourceForm.touched) {

      this.resource.name = this.resourceForm.value.name;
      this.resource.resourceId = this.resourceForm.value.resourceId;
      this.resource.resourceType = this.resourceForm.value.resourceType;
      this.resource.resourceSecret = this.resourceForm.value.resourceSecret;
      this.resource.scopes = this.resourceForm.value.scopes;

      this.resourceSvc.save(this.resource).subscribe(x => {
        console.log(x);
        this.resourceForm.markAsPristine();
        this.resourceForm.markAsUntouched();
      },
        error => {
          this.showErrorToast(error, 'Fout bij het opslaan van de resource');
        })


    }
  }
  
  ScopeVerwijderen(c: AbstractControl) {
    let scopesArray = this.resourceForm.controls['scopes'] as FormArray;

    var idx = scopesArray.controls.findIndex(x => x === c);

    if (idx !== -1) {
      scopesArray.removeAt(idx);
      this.resourceForm.markAsDirty();
      this.resourceForm.markAsTouched();
    }
  }


  ScopeToevoegen() {

    let scopesArray = this.resourceForm.controls['scopes'] as FormArray;

    var nieuweScope = this.fb.group({
      scopeId: [0],
      resourceId: [this.resource.resourceId],
      description: [this.newScopeForm.value.description],
      scopeType: [this.newScopeForm.value.scopeType],
      name: [this.newScopeForm.value.name]
    });

    nieuweScope.markAsTouched();
    nieuweScope.markAsDirty();

    scopesArray.push(nieuweScope);

    this.resourceForm.markAsDirty();
    this.resourceForm.markAsTouched();

    this.newScopeForm = this.getNewScopeForm();
  }


  appUsersZoeken() {

    this.appUserSvc.get(this.zoekTerm.value).subscribe(a => this.zoekTermTreffers = a);
  }

  toevoegenGekozenBeheerder(event: MatAutocompleteSelectedEvent) {

    console.log(event);
    
    var bestaandeBeheerder = this.beheerders.find(b => { return b.appUserId === event.option.value });

    if (!bestaandeBeheerder) {
      this.resourceSvc.addBeheerder(this.resource.resourceId, event.option.value).subscribe(b => {
        this.beheerders = b;
        this.zoekTerm.reset();

      });
    }
  }

  verwijderBeheerder(appUser: AppUser) {
    
    this.resourceSvc.removeBeheerder(this.resource.resourceId, appUser.appUserId).subscribe(b => {
        this.beheerders = b;
      });
  }

  showErrorToast(error: any, melding: string) {

    let tekst = melding;

    if (!tekst && error.message) {
      tekst += '(' + error.message + ')';
    }

    this.snackBar.open(tekst, null, { duration: 3000 });
  }
}
