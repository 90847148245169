import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Client } from '../models/client';
import { ClientSecret } from '../models/client-secret';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient, private appConfigSvc: AppConfigService) { }

  get() {
    return this.http.get<Client[]>(this.appConfigSvc.getConfig().apiRoot + '/Client');
  }

  fetch(clientId: string) {
    return this.http.get<Client>(this.appConfigSvc.getConfig().apiRoot + '/Client/' + clientId);
  }

  getSecrets(clientId: string) {
    return this.http.get<ClientSecret[]>(this.appConfigSvc.getConfig().apiRoot + '/Client/' + clientId + '/Secrets');
  }

  saveSecret(clientSecret: ClientSecret) {
    if (clientSecret.clientSecretId) {
      return this.http.put<ClientSecret>(this.appConfigSvc.getConfig().apiRoot + '/Client/' + clientSecret.clientId + '/Secrets/' + clientSecret.clientSecretId, clientSecret);
    } else {
      return this.http.post<ClientSecret>(this.appConfigSvc.getConfig().apiRoot + '/Client/' + clientSecret.clientId + '/Secrets', clientSecret);
    }
    
  }

  deleteSecret(clientSecret: ClientSecret) {
    return this.http.delete(this.appConfigSvc.getConfig().apiRoot + '/Client/' + clientSecret.clientId + '/Secrets/' + clientSecret.clientSecretId);
  }

  save(client: Client) {
    return this.http.put<Client>(this.appConfigSvc.getConfig().apiRoot + '/Client/' + client.clientId, client);
  }
}
