<app-toolbar appinfo="appinfo" titel="Beheer Resource">Beheer Resource</app-toolbar>

<div class="content" fxLayout="column">

  <mat-card style="margin-top:8px;">
    <div fxLayout="row">
      <button mat-raised-button color="primary" (click)="RecordOpslaan()" [disabled]="!resourceForm.dirty" style="margin: 8px;">Opslaan</button>
      <mat-icon *ngIf="resourceForm.dirty">edit</mat-icon>
    </div>
    <div style="padding: 8px;">
      <form [formGroup]="resourceForm">
        <div fxLayout="column wrap">

          <input formControlName="resourceId" placeholder="resourceId" type="hidden">

          <mat-form-field>
            <input matInput formControlName="name" placeholder="Naam">
          </mat-form-field>

          <mat-form-field>
            <mat-select formControlName="resourceType" placeholder="Type">
              <mat-option [value]="0">Identity</mat-option>
              <mat-option [value]="1">Api</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <input matInput formControlName="resourceSecret" placeholder="Resource Secret">
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <h3>Scopes</h3>
        </div>

        <table style="width: 100%;">
          <thead>
            <tr>
              <th style="text-align:left;" fxFlex="20">Name</th>
              <th style="text-align:left;" fxFlex="50">Description</th>
              <th style="text-align:left;" fxFlex="20">Type</th>
              <th></th>
            </tr>
          </thead>


          <tbody formArrayName="scopes">
            <tr *ngFor="let s of resourceForm.get('scopes')['controls']; let i = index;">
              <ng-container formGroupName="{{i}}">
                <td fxFlex="20">
                  <mat-form-field style="width:100%">
                    <input matInput formControlName="name" placeholder="Name">
                  </mat-form-field>
                </td>
                <td fxFlex="50">
                  <mat-form-field style="width:100%">
                    <input matInput formControlName="description" placeholder="Description">
                  </mat-form-field>
                </td>
                <td fxFlex="20">
                  <mat-form-field style="width:100%">
                    <mat-select formControlName="scopeType" placeholder="Type">
                      <mat-option [value]="0">Identity</mat-option>
                      <mat-option [value]="1">Api</mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
                <td fxFlex="10">
                  <button mat-icon-button (click)="ScopeVerwijderen(s)">
                    <mat-icon>clear</mat-icon>
                  </button>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>

      </form>
      <form [formGroup]="newScopeForm">
        <table style="width: 100%;">

          <tbody>
            <tr>
              <td>
                <mat-form-field>
                  <input matInput formControlName="name" placeholder="Name">
                </mat-form-field>
              </td>
              <td>
                <mat-form-field>
                  <input matInput formControlName="description" placeholder="Description">
                </mat-form-field>
              </td>
              <td>

                <mat-form-field>
                  <mat-select formControlName="scopeType" placeholder="Type">
                    <mat-option [value]="0">Identity</mat-option>
                    <mat-option [value]="1">Api</mat-option>
                  </mat-select>
                </mat-form-field>

              </td>
              <td>
                <button mat-icon-button (click)="ScopeToevoegen()">
                  <mat-icon>arrow_upward</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>


  </mat-card>
  <mat-card style="margin-top: 5px;">
    <div fxLayout="row">
      <h3>Beheerders</h3>
    </div>
    <div>
      <div *ngFor="let b of beheerders">
        {{ b.name }}    <button mat-icon-button (click)="verwijderBeheerder(b)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <div>
      <p *ngIf="beheerders.length === 0">Er zijn geen beheerders gekoppeld</p>

      <mat-form-field>
        <mat-icon matSuffix>search</mat-icon>
        <input type="text" placeholder="Zoeken en toevoegen beheerder" aria-label="Zoekterm invullen" matInput
               [formControl]="zoekTerm" [matAutocomplete]="auto" (keyup.enter)="appUsersZoeken()">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)='toevoegenGekozenBeheerder($event)'>
          <mat-option *ngFor="let au of zoekTermTreffers" [value]="au.appUserId">
            {{ au.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

    </div>
  </mat-card>
</div>
