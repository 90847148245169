import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DeviceCodeIdpRp } from '../models/device-code-idp-rp';
@Injectable({
  providedIn: 'root'
})
export class DeviceCodeIdpRpService {

  constructor(private http: HttpClient, private appConfigSvc: AppConfigService) { }

  get() {
    return this.http.get<DeviceCodeIdpRp[]>(this.appConfigSvc.getConfig().apiRoot + '/DeviceCodeIdpRp');
  }

  fetch(deviceCodeIdpRpId: number) {
    return this.http.get<DeviceCodeIdpRp>(this.appConfigSvc.getConfig().apiRoot + '/DeviceCodeIdpRp/' + deviceCodeIdpRpId);
  }

  save(rp: DeviceCodeIdpRp) {
    if (rp.deviceCodeIdpRpId) {
      return this.http.put<DeviceCodeIdpRp>(this.appConfigSvc.getConfig().apiRoot + '/DeviceCodeIdpRp/' + rp.deviceCodeIdpRpId, rp);
    } else {
      return this.http.post<DeviceCodeIdpRp>(this.appConfigSvc.getConfig().apiRoot + '/DeviceCodeIdpRp', rp);
    }
  }

  delete(deviceCodeIdpRpId: number) {
    return this.http.delete(this.appConfigSvc.getConfig().apiRoot + '/DeviceCodeIdpRp/' + deviceCodeIdpRpId);
  }
}
