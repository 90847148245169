import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IdentityProvider } from '../models/identity-provider';
import { IdentityProviderService } from '../services/identity-provider.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-identity-providers',
  templateUrl: './identity-providers.component.html',
  styleUrls: ['./identity-providers.component.scss']
})
export class IdentityProvidersComponent implements OnInit {

  dataSource = new MatTableDataSource<IdentityProvider>();
  displayedColumns: string[] = ['identityProviderId', 'caption', 'authenticationType', 'redirectUri','isActive' ];

  @ViewChild(MatSort, { static: true }) sort: MatSort;


  constructor(private identityProviderSvc: IdentityProviderService, private router: Router) { }

  ngOnInit() {

    this.identityProviderSvc.get().subscribe(data => {
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
    });
  }

  public Details(identityProvider: IdentityProvider) {
    this.router.navigate(['beheeridp', { identityProviderId: identityProvider.identityProviderId }]);
  }

  public RecordToevoegen() {
    this.router.navigate(['beheeridp', { identityProviderId: 0 }]);
  }
}
