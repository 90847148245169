import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-toolbar',
  inputs: ['titel'],
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  constructor(public appreference: AppComponent) { }

  titel: string = 'Identity Beheer';
  ngOnInit() {
  }

  public toggleMenu() {

    this.appreference.toggleMenu();
  }

}
