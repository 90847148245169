import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { AppUser } from '../models/app-user';
import { PersistedGrantInfo } from '../models/persisted-grant-info';

@Injectable({
  providedIn: 'root'
})
export class AppUserService {

  constructor(private http: HttpClient, private appConfigSvc: AppConfigService) { }

  get(naam: string) {

    let params = new HttpParams()
      .append('naam', naam ? naam : '');
    return this.http.get<AppUser[]>(this.appConfigSvc.getConfig().apiRoot + '/AppUser', { params: params });
  }

  fetch(id) {
    return this.http.get<AppUser>(this.appConfigSvc.getConfig().apiRoot + '/AppUser/' + id);
  }

  audit(id) {
    return this.http.get<AppUser[]>(this.appConfigSvc.getConfig().apiRoot + '/AppUser/' + id + '/audit');
  }

  grants(id) {
    return this.http.get<PersistedGrantInfo[]>(this.appConfigSvc.getConfig().apiRoot + '/AppUser/' + id + '/grants');
  }
  
  save(appUser: AppUser) {
    return this.http.put<AppUser>(this.appConfigSvc.getConfig().apiRoot + '/AppUser/' + appUser.appUserId, appUser);
  }
}
