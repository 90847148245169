import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StartComponent } from './start/start.component';
import { AuthGuardService } from './services/auth-guard.service';
import { IdentityProvidersComponent } from './identity-providers/identity-providers.component';
import { IdentityProviderComponent } from './identity-provider/identity-provider.component';
import { ResourcesComponent } from './resources/resources.component';
import { ResourceComponent } from './resource/resource.component';
import { AppUsersComponent } from './app-users/app-users.component';
import { AppUserComponent } from './app-user/app-user.component';
import { ClientsComponent } from './clients/clients.component';
import { ClientComponent } from './client/client.component';
import { BeheerSettingsComponent } from './beheer-settings/beheer-settings.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { DeviceCodeRpsComponent } from './device-code-rps/device-code-rps.component';
import { DeviceCodeRpComponent } from './device-code-rp/device-code-rp.component';
import { DeviceTokensComponent } from './device-tokens/device-tokens.component';
import { DeviceTokenComponent } from './device-token/device-token.component';

const routes: Routes = [
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'logout', component: UnauthorizedComponent },
  { path: '', component: StartComponent, canActivate: [AuthGuardService] },
  { path: 'appusers', component: AppUsersComponent, canActivate: [AuthGuardService] },
  { path: 'appuser', component: AppUserComponent, canActivate: [AuthGuardService] },
  { path: 'resources', component: ResourcesComponent, canActivate: [AuthGuardService] },
  { path: 'resource', component: ResourceComponent, canActivate: [AuthGuardService] },
  { path: 'clients', component: ClientsComponent, canActivate: [AuthGuardService] },
  { path: 'client', component: ClientComponent, canActivate: [AuthGuardService] },
  { path: 'beheeridps', component: IdentityProvidersComponent, canActivate: [AuthGuardService] },
  { path: 'beheeridp', component: IdentityProviderComponent, canActivate: [AuthGuardService] },
  { path: 'settings', component: BeheerSettingsComponent, canActivate: [AuthGuardService] },
  { path: 'devicecodeidprps', component: DeviceCodeRpsComponent, canActivate: [AuthGuardService] },
  { path: 'devicecodeidprp', component: DeviceCodeRpComponent, canActivate: [AuthGuardService] },
  { path: 'devicetokens', component: DeviceTokensComponent, canActivate: [AuthGuardService] },
  { path: 'devicetoken', component: DeviceTokenComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
