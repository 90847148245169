import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { AppInfo } from '../models/app-info';

@Injectable({
  providedIn: 'root'
})
export class AppInfoService {

  constructor(private http: HttpClient, private appConfigSvc: AppConfigService) { }

  get() {
    return this.http.get<AppInfo>(this.appConfigSvc.getConfig().apiRoot + '/AppInfo');
  }
}
