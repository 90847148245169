import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppUserService } from '../services/app-user.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppUser } from '../models/app-user';
import { ResourceService } from '../services/resource.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Scope } from '../models/scope';
import { ClaimMappingService } from '../services/claim-mapping.service';
import { forkJoin } from 'rxjs';
import { ClaimMapping } from '../models/claim-mapping';
import * as moment from 'moment';
import { PersistedGrantInfo } from '../models/persisted-grant-info';

@Component({
  selector: 'app-app-user',
  templateUrl: './app-user.component.html',
  styleUrls: ['./app-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppUserComponent implements OnInit {

  appUser: AppUser;
  appUserForm: FormGroup = this.getAppUserFormGroup(null);
  claimsForm: FormGroup = this.getClaimsFormGroup();

  existingClaims: ClaimMapping[];


  dataSourceLogboek = new MatTableDataSource<AppUser>();
  displayedColumnsLogboek: string[] = ['lastLoginDateTime', 'lastLoginIpAddress', 'lastLoginUserAgent', 'isDisabled'];
  @ViewChild('sortLogboek', { static: true }) sortLogboek: MatSort;

  dataSourceGrants = new MatTableDataSource<PersistedGrantInfo>();
  displayedColumnsGrants: string[] = ['persistedGrantType', 'clientId', 'creationTime', 'expiration'];
  @ViewChild('sortGrants', { static: true }) sortGrants: MatSort;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private appUserSvc: AppUserService,
    private resourceSvc: ResourceService,
    private claimMappingSvc: ClaimMappingService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {

    this.claimsForm = this.getClaimsFormGroup();

    this.appUserSvc.fetch(+this.route.snapshot.params.appUserId)
      .subscribe((i: AppUser) => {
        this.appUser = i;
        this.appUserForm = this.getAppUserFormGroup(this.appUser);

        this.loadClaimMappings(this.appUser);
        this.auditHistory(this.appUser);
        this.loadGrants(this.appUser);

      })

  }

  loadGrants(appUser: AppUser) {
    this.appUserSvc.grants(appUser.appUserId).subscribe(g => {
      this.dataSourceGrants.data = g;
      this.dataSourceGrants.sort = this.sortGrants;

    });
  }

  loadClaimMappings(appUser: AppUser) {

    forkJoin(
      this.claimMappingSvc.get(appUser.identityProviderId, appUser.identityProviderSubClaimValue),
      this.resourceSvc.get()
    ).subscribe(([c, r]) => {

      this.existingClaims = c;

      let scopes: Scope[] = [];

      r.forEach(item => {
        scopes = scopes.concat(item.scopes.map(s => {
          return s;
        }));
      });

      this.claimsForm = this.getClaimsFormGroup();
      let claimsFormArray: FormArray = this.claimsForm.get('items') as FormArray;

      scopes.forEach(s => {

        if (s.scopeType === 1) {
          var matchingClaim = c.find(a => a.outboundClaimValue === s.name && a.outboundClaimType === 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role');

          claimsFormArray.push(this.createClaimMappingFormGroup(
            s.name,
            matchingClaim ? true : false,
            matchingClaim ? matchingClaim.validFrom : null,
            matchingClaim ? matchingClaim.validTo : null));
        }
      });


    });
  }

  auditHistory(appUser: AppUser) {
    this.appUserSvc.audit(appUser.appUserId).subscribe(data => {
      this.dataSourceLogboek.data = data;
      this.dataSourceLogboek.sort = this.sortLogboek;
    });

  }
  createClaimMappingFormGroup(scope: string, enabled: boolean, van: Date, tot: Date): FormGroup {

    return this.fb.group({
      enabled: enabled,
      outboundClaimValue: scope,
      validFrom: van,
      validTo: tot
    });
  }

  opslaanClaims() {


    for (var i in this.claimsForm.controls['items']['controls']) {

      var row = this.claimsForm.controls['items']['controls'][i];

      if (row.touched) {
        console.log('Touched: ');
        console.log(row);

        var matchingClaim = this.existingClaims.find(a => a.outboundClaimValue === row.value.outboundClaimValue
          && a.outboundClaimType === 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role');

        if (!matchingClaim) {
          // Nieuwe claim

          var from: Date = moment(row.value.validFrom ? row.value.validFrom : '2000-01-01').toDate()
          var to: Date = row.value.validTo ? moment(row.value.validTo).toDate() : null;

          var nieuweClaim: ClaimMapping = {
            claimMappingId: 0,
            identityProviderId: this.appUser.identityProviderId,
            inboundClaimType: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
            inboundClaimValue: this.appUser.identityProviderSubClaimValue,
            outboundClaimType: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
            outboundClaimValue: row.value.outboundClaimValue,
            validFrom: from,
            validTo: to
          };

          this.claimMappingSvc.save(nieuweClaim).subscribe(r => {
            this.loadClaimMappings(this.appUser);
          });

        } else {
          // Bestaande claim

          if (!row.value.enabled) {
            // Delete
            this.claimMappingSvc.delete(matchingClaim).subscribe(r => {
              console.log(r);
              this.loadClaimMappings(this.appUser);
            },
              error => {
                this.showErrorToast(error, 'Fout bij het verwijderen van de mapping');
              });

          } else {
            // Update
            matchingClaim.validFrom = moment(row.value.validFrom).toDate();
            matchingClaim.validTo = moment(row.value.validTo).toDate();

            this.claimMappingSvc.save(matchingClaim).subscribe(r => {
              console.log(r);
              row.markAsPristine();
              row.markAsUntouched();
              this.loadClaimMappings(this.appUser);
            },
              error => {
                this.showErrorToast(error, 'Fout bij het opslaan van de mapping');
              });

          }
        }
      }
    }

    

  }

  getClaimsFormGroup(): FormGroup {
    return this.fb.group({ items: this.fb.array([]) });
  }

  opslaanAlgemeen() {
    console.log('Opslaan algemeen');
    console.log(this.appUserForm);

    if (this.appUserForm.touched) {

      this.appUser.isDisabled = this.appUserForm.value.isDisabled;
      this.appUser.emailAddress = this.appUserForm.value.emailAddress;
      this.appUser.phoneNumber = this.appUserForm.value.phoneNumber;
      this.appUser.remarks = this.appUserForm.value.remarks;
      this.appUser.validFrom = moment(this.appUserForm.value.validFrom).toDate();
      this.appUser.validUntil = this.appUserForm.value.validUntil ? moment(this.appUserForm.value.validUntil).toDate() : null;

      this.appUserSvc.save(this.appUser).subscribe(a => {
        console.log(a);
        this.appUserForm.markAsPristine();
        this.appUserForm.markAsUntouched();
      },
        error => {
          this.showErrorToast(error, 'Fout bij het opslaan van de gebruiker');
        });

    }

  }

  getAppUserFormGroup(a: AppUser): FormGroup {

    if (!a) {
      return this.fb.group({
        appUserId: [{ value: '', disabled: true }],
        name: [{ value: '', disabled: true }],
        identityProviderCaption: [{ value: '', disabled: true }],
        identityProviderSubClaimValue: [{ value: '', disabled: true }],
        emailAddress: [{ value: '', disabled: true }],
        isDisabled: [''],
        remarks: [''],
        validFrom: [''],
        validUntil: [''],
        phoneNumber: ['']
      });
    }

    return this.fb.group({
      appUserId: [{ value: a.appUserId, disabled: true }],
      name: [{ value: a.name, disabled: true }],
      identityProviderCaption: [{ value: a.identityProviderCaption, disabled: true }],
      identityProviderSubClaimValue: [{ value: a.identityProviderSubClaimValue, disabled: true }],
      emailAddress: [{ value: a.emailAddress, disabled: true }],
      isDisabled: [a.isDisabled],
      remarks: [a.remarks],
      validFrom: [a.validFrom],
      validUntil: [a.validUntil],
      phoneNumber: [a.phoneNumber]
    });
  }

  showErrorToast(error: any, melding: string) {

    let tekst = melding;

    if (!tekst && error.message) {
      tekst += '(' + error.message + ')';
    }

    this.snackBar.open(tekst, null, { duration: 3000 });
  }

}
