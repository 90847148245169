import { Component, OnInit } from '@angular/core';
import { IdentityProvider } from '../models/identity-provider';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { IdentityProviderService } from '../services/identity-provider.service';
import { switchMap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-identity-provider',
  templateUrl: './identity-provider.component.html',
  styleUrls: ['./identity-provider.component.scss']
})
export class IdentityProviderComponent implements OnInit {

  identityProvider: IdentityProvider;
  identityProviderForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private identityProviderSvc: IdentityProviderService,
    private snackBar: MatSnackBar

  ) { }

  ngOnInit() {

    let identityProviderId: number = +this.route.snapshot.params.identityProviderId;

    if (identityProviderId) {
      this.identityProviderSvc.fetch(identityProviderId)
        .subscribe((i: IdentityProvider) => {
          this.identityProvider = i;
          this.identityProviderForm = this.getFormGroup(this.identityProvider);
        })
    } else {
      this.identityProvider = {
        identityProviderId: 0,
        authenticationType: 'Google',
        caption: '',
        signInId: '',
        signInSecret: '',
        metadataAddress: '',
        redirectUri: '',
        postLogoutRedirect: '',
        disableNewUsers: true,
        isActive: true
      }
      this.identityProviderForm = this.getFormGroup(this.identityProvider);
    }
    
  }
  
  getFormGroup(idp: IdentityProvider): FormGroup {
    return this.fb.group({
      identityProviderId: [idp.identityProviderId],
      caption: [idp.caption, Validators.required],
      authenticationType: [idp.authenticationType, Validators.required],
      signInId: [idp.signInId, Validators.required],
      signInSecret: [idp.signInSecret, Validators.required],
      redirectUri: [idp.redirectUri],
      metadataAddress: [idp.metadataAddress],
      postLogoutRedirect: [idp.postLogoutRedirect],
      disableNewUsers: [idp.disableNewUsers],
      isActive: [idp.isActive]
  });
  }

  RecordOpslaan() {

    this.identityProviderForm.updateValueAndValidity();

    if (this.identityProviderForm.touched && this.identityProviderForm.valid) {
      this.identityProvider.caption = this.identityProviderForm.value.caption;
      this.identityProvider.authenticationType = this.identityProviderForm.value.authenticationType;
      this.identityProvider.signInId = this.identityProviderForm.value.signInId;
      this.identityProvider.signInSecret = this.identityProviderForm.value.signInSecret;
      this.identityProvider.redirectUri = this.identityProviderForm.value.redirectUri;
      this.identityProvider.metadataAddress = this.identityProviderForm.value.metadataAddress;
      this.identityProvider.postLogoutRedirect = this.identityProviderForm.value.postLogoutRedirect;
      this.identityProvider.disableNewUsers = this.identityProviderForm.value.disableNewUsers;
      this.identityProvider.isActive = this.identityProviderForm.value.isActive;

      this.identityProviderSvc.save(this.identityProvider).subscribe(a => {
        console.log(a);
        this.identityProviderForm.markAsPristine();
        this.identityProviderForm.markAsUntouched();
      },
        error => {
          this.showErrorToast(error, 'Fout bij het opslaan van de provider');
        });

    }
    
  }

  showErrorToast(error: any, melding: string) {

    let tekst = melding;

    if (!tekst && error.message) {
      tekst += '(' + error.message + ')';
    }

    this.snackBar.open(tekst, null, { duration: 3000 });
  }

}
