import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Resource } from '../models/resource';
import { ResourceService } from '../services/resource.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {

  dataSource = new MatTableDataSource<Resource>();
  displayedColumns: string[] = ['name', 'resourceType'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;


  constructor(private resourceSvc: ResourceService, private router: Router) { }

  ngOnInit() {

    this.resourceSvc.get().subscribe(data => {
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
    });
  }

  public Details(resource: Resource) {
    this.router.navigate(['resource', { resourceId: resource.resourceId }]);
  }

  public RecordToevoegen() {
    this.router.navigate(['resource', { resourceId: 0 }]);
  }
}
