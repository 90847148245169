<app-toolbar appinfo="appinfo" titel="Beheer Identityprovider">Beheer Identityprovider</app-toolbar>

<div class="content" fxLayout="column">
  <div fxLayout="row">
    <button mat-raised-button color="primary" (click)="RecordOpslaan()" [disabled]="!identityProviderForm.dirty" style="margin: 8px;">Opslaan</button>
    <mat-icon class="dirtyIcon" *ngIf="identityProviderForm.dirty">edit</mat-icon>
  </div>
  <mat-card style="margin-top:8px;">
    <div style="padding: 8px;">
      <form [formGroup]="identityProviderForm">
        <div fxLayout="column wrap">

          <input formControlName="identityProviderId" placeholder="identityProviderId" type="hidden">

          <mat-form-field>
            <input matInput formControlName="caption" placeholder="Naam">
          </mat-form-field>
          <mat-form-field>
            <mat-select formControlName="authenticationType" placeholder="Type">
              <mat-option value="Google">Google</mat-option>
              <mat-option value="Federation">Federation</mat-option>
              <mat-option value="Microsoft">Microsoft</mat-option>
              <mat-option value="OpenIdConnect">OpenIdConnect</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <input matInput formControlName="signInId" placeholder="SignIn Id">
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="signInSecret" placeholder="SignIn Secret">
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="metadataAddress" placeholder="MetadataAddress">
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="redirectUri" placeholder="Redirect Uri">
          </mat-form-field>


          <mat-form-field>
            <input matInput formControlName="postLogoutRedirect" placeholder="PostLogoutRedirect">
          </mat-form-field>
          <mat-form-field>
            <mat-select formControlName="disableNewUsers" placeholder="Disable Nieuwe Users">
              <mat-option [value]="true">Nieuwe users zijn disabled</mat-option>
              <mat-option [value]="false">Nieuwe users zijn direct actief</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select formControlName="isActive" placeholder="Is Actief">
              <mat-option [value]="true">Ja</mat-option>
              <mat-option [value]="false">Nee</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>
  </mat-card>
</div>
