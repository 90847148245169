import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StartComponent } from './start/start.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { AppConfigService } from './services/app-config.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './services/token.interceptor';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { MaterialModule } from './material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, FormControl, ReactiveFormsModule } from '@angular/forms';
import { IdentityProvidersComponent } from './identity-providers/identity-providers.component';
import { IdentityProviderComponent } from './identity-provider/identity-provider.component';
import { ResourcesComponent } from './resources/resources.component';
import { ResourceComponent } from './resource/resource.component';
import { AppUsersComponent } from './app-users/app-users.component';
import { AppUserComponent } from './app-user/app-user.component';
import { MomentModule } from 'ngx-moment';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ClientsComponent } from './clients/clients.component';
import { ClientComponent } from './client/client.component';
import { BeheerSettingsComponent, AppSettingDialog } from './beheer-settings/beheer-settings.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { DeviceCodeRpsComponent } from './device-code-rps/device-code-rps.component';
import { DeviceCodeRpComponent } from './device-code-rp/device-code-rp.component';
import { DeviceTokensComponent } from './device-tokens/device-tokens.component';
import { DeviceTokenComponent } from './device-token/device-token.component';

export function init_app(appConfig: AppConfigService) {
  return () => appConfig.loadAppConfig();
}

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    ToolbarComponent,
    IdentityProvidersComponent,
    IdentityProviderComponent,
    ResourcesComponent,
    ResourceComponent,
    AppUsersComponent,
    AppUserComponent,
    ClientsComponent,
    ClientComponent,
    BeheerSettingsComponent,
    AppSettingDialog,
    UnauthorizedComponent,
    DeviceCodeRpsComponent,
    DeviceCodeRpComponent,
    DeviceTokensComponent,
    DeviceTokenComponent
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MomentModule
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      multi: true,
      deps: [AppConfigService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthGuardService,
    AuthService,
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'nl-NL'
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [AppSettingDialog]
})
export class AppModule { }
