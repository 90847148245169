<app-toolbar appinfo="appinfo" titel="Beheer Identityproviders">Beheer Identityproviders</app-toolbar>
<div class="content" fxLayout="column">

  <button mat-raised-button (click)="RecordToevoegen()" style="margin: 8px; margin-left:0; width:300px;">Identityprovider toevoegen</button>
 
  <table mat-table matSort [dataSource]="dataSource">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="Details(row)" class="clickable"></tr>

    <ng-container matColumnDef="identityProviderId">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.identityProviderId }}
      </td>
    </ng-container>
    <ng-container matColumnDef="caption">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Naam</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.caption }}
      </td>
    </ng-container>
    <ng-container matColumnDef="authenticationType">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.authenticationType }}
      </td>
    </ng-container>
    <ng-container matColumnDef="redirectUri">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Redirect</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.redirectUri }}
      </td>
    </ng-container>
    <ng-container matColumnDef="isActive">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Actief</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.isActive }}
      </td>
    </ng-container>
  </table>

</div>
