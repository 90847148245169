<app-toolbar appinfo="appinfo" titel="Beheer Client">Beheer Client</app-toolbar>

<div class="content" fxLayout="column">

  <mat-card style="margin-top:8px;">
    <div fxLayout="row">
      <button mat-raised-button color="primary" (click)="RecordOpslaan()" [disabled]="!clientForm.dirty" style="margin: 8px;">Opslaan</button>
      <mat-icon class="dirtyIcon" *ngIf="clientForm.dirty">edit</mat-icon>
    </div>

    <div style="padding: 8px;">
      <form [formGroup]="clientForm">
        <div fxLayout="column wrap">

          <mat-form-field>
            <input matInput formControlName="clientId" placeholder="ClientId">
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="clientName" placeholder="Naam">
          </mat-form-field>

          <mat-form-field>
            <mat-select formControlName="enabled" placeholder="Actief">
              <mat-option [value]="true">Client is actief</mat-option>
              <mat-option [value]="false">Client is NIET actief</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select formControlName="allowAccessTokensViaBrowser" placeholder="Tokens via browser toestaan">
              <mat-option [value]="true">Toegestaan</mat-option>
              <mat-option [value]="false">NIET toestaan</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-select formControlName="allowedGrantTypes" placeholder="Allowed Granttype">
              <mat-option value="implicit">implicit</mat-option>
              <mat-option value="client_credentials">client_credentials</mat-option>
              <mat-option value="authorization_code">authorization_code</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <input matInput formControlName="accessTokenLifetime" placeholder="AccessTokenLifetime" type="number">
          </mat-form-field>

          <mat-form-field>
            <mat-select formControlName="refreshTokenExpiration" placeholder="RefreshTokenExpiration">
              <mat-option [value]="0">Sliding</mat-option>
              <mat-option [value]="1">Absolute</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-select formControlName="refreshTokenUsage" placeholder="RefreshTokenUsage">
              <mat-option [value]="0">ReUse</mat-option>
              <mat-option [value]="1">OneTimeOnly</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <input matInput formControlName="absoluteRefreshTokenLifetime" placeholder="AbsoluteRefreshTokenLifetime" type="number">
          </mat-form-field>

          <mat-form-field>
            <input matInput formControlName="slidingRefreshTokenLifetime" placeholder="SlidingRefreshTokenLifetime" type="number">
          </mat-form-field>

          <mat-form-field>
            <input matInput formControlName="identityTokenLifetime" placeholder="IdentityTokenLifetime" type="number">
          </mat-form-field>


          <mat-form-field>
            <mat-select formControlName="updateAccessTokenClaimsOnRefresh" placeholder="Update accesstoken">
              <mat-option [value]="true">Claims bijwerken</mat-option>
              <mat-option [value]="false">Claims niet bijwerken</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-select formControlName="allowOfflineAccess" placeholder="AllowOfflineAccess">
              <mat-option [value]="true">Toegestaan</mat-option>
              <mat-option [value]="false">Niet toegstaan</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-select formControlName="requirePkce" placeholder="RequirePkce">
              <mat-option [value]="true">PKCE vereist</mat-option>
              <mat-option [value]="false">PKCE niet vereist</mat-option>
            </mat-select>
          </mat-form-field>


          <mat-form-field>
            <mat-label>Allowed Scopes</mat-label>
            <mat-select formControlName="allowedScopes" multiple>
              <mat-option *ngFor="let s of allScopes" [value]="s">{{ s }}</mat-option>

            </mat-select>
          </mat-form-field>

          <mat-card style="margin-top:8px;">
            <div>
              <table style="width: 100%;">
                <thead>
                  <tr>
                    <th style="text-align:left;">Toegestane redirect uri's</th>

                  </tr>
                </thead>

                <tbody formArrayName="allowedRedirectUris">
                  <tr *ngFor="let c of clientForm.get('allowedRedirectUris')['controls']; let i = index;">
                    <ng-container formGroupName="{{i}}">

                      <td>
                        <mat-form-field fxFlex>
                          <input matInput formControlName="allowedRedirectUri" placeholder="Uri">
                        </mat-form-field>
                        <button mat-icon-button (click)="RedirectUriVerwijderen(c)">
                          <mat-icon>clear</mat-icon>
                        </button>
                      </td>

                    </ng-container>
                  </tr>


                </tbody>
              </table>


            </div>
            <mat-form-field style="width: 100%;">
              <input #nieuweUri matInput placeholder="Nieuwe uri" (change)="nieuweUriChanged()">
            </mat-form-field>
          </mat-card>

          <mat-card style="margin-top:8px;">
            <div>
              <table style="width: 100%;">
                <thead>
                  <tr>
                    <th style="text-align:left;">Post logout redirect Uri's</th>

                  </tr>
                </thead>

                <tbody formArrayName="postLogoutRedirectUris">
                  <tr *ngFor="let c of clientForm.get('postLogoutRedirectUris')['controls']; let i = index;">
                    <ng-container formGroupName="{{i}}">

                      <td>
                        <mat-form-field fxFlex>
                          <input matInput formControlName="postLogoutRedirectUri" placeholder="Uri">
                        </mat-form-field>
                        <button mat-icon-button (click)="PostLogoutRedirectUriVerwijderen(c)">
                          <mat-icon>clear</mat-icon>
                        </button>
                      </td>

                    </ng-container>
                  </tr>


                </tbody>
              </table>


            </div>
            <mat-form-field style="width: 100%;">
              <input #nieuwePostLogoutUri matInput placeholder="Nieuwe uri" (change)="nieuwePostLogoutUriChanged()">
            </mat-form-field>
          </mat-card>


          <mat-card style="margin-top:8px;">
            <div>
              <table style="width: 100%;">
                <thead>
                  <tr>
                    <th style="text-align:left;">Toegestane CORS origins</th>

                  </tr>
                </thead>

                <tbody formArrayName="allowedCorsOrigins">
                  <tr *ngFor="let c of clientForm.get('allowedCorsOrigins')['controls']; let i = index;">
                    <ng-container formGroupName="{{i}}">

                      <td>
                        <mat-form-field fxFlex>
                          <input matInput formControlName="allowedCorsOrigin" placeholder="Origin">
                        </mat-form-field>
                        <button mat-icon-button (click)="CorsOriginVerwijderen(c)">
                          <mat-icon>clear</mat-icon>
                        </button>
                      </td>

                    </ng-container>
                  </tr>


                </tbody>
              </table>


            </div>
            <mat-form-field style="width: 100%;">
              <input #nieuweOrigin matInput placeholder="Nieuwe origin" (change)="nieuweOriginChanged()">
            </mat-form-field>
          </mat-card>

        </div>
      </form>
    </div>
  </mat-card>

  <mat-card style="margin-top:8px;" *ngIf="client && client.clientId">
    <div fxLayout="row">
      <h3>Client secrets</h3>
      <button mat-raised-button color="primary" (click)="SecretsOpslaan()" [disabled]="!clientSecretsForm.dirty" style="margin: 8px;">Opslaan</button>
      <mat-icon class="dirtyIcon" *ngIf="clientSecretsForm.dirty">edit</mat-icon>
    </div>

    <div style="padding: 8px;">

      <div fxLayout="column wrap">

        <form [formGroup]="clientSecretsForm">
          <table style="width: 100%;">
            <thead>
              <tr>
                <th style="text-align:left;">Description</th>
                <th style="text-align:left;">Value</th>
                <th style="text-align:left;">Geldig van</th>
                <th style="text-align:left;">Geldig tot</th>
                <th></th>
              </tr>
            </thead>


            <tbody formArrayName="secrets">
              <tr *ngFor="let c of clientSecretsForm.get('secrets').controls; let i = index;">
                <ng-container formGroupName="{{i}}">

                  <td>
                    <mat-form-field>
                      <input matInput formControlName="description" placeholder="Description">
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field>
                      <input matInput formControlName="value" placeholder="Value">
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field style="margin-right: 5px;">
                      <input matInput [matDatepicker]="pickerVan" placeholder="Geldig Van" formControlName="validFrom">
                      <mat-datepicker-toggle matSuffix [for]="pickerVan"></mat-datepicker-toggle>
                      <mat-datepicker #pickerVan></mat-datepicker>
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field style="margin-right: 5px;">
                      <input matInput [matDatepicker]="pickerTot" placeholder="Geldig Tot" formControlName="validUntil">
                      <mat-datepicker-toggle matSuffix [for]="pickerTot"></mat-datepicker-toggle>
                      <mat-datepicker #pickerTot></mat-datepicker>
                    </mat-form-field>
                  </td>
                  <td>
                    <button mat-icon-button (click)="SecretVerwijderen(c)">
                      <mat-icon>clear</mat-icon>
                    </button>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </form>
        <form [formGroup]="nieuwSecretForm">
          <table style="width: 100%;">
            <tbody>
              <tr>

                <td>
                  <mat-form-field>
                    <input matInput formControlName="description" placeholder="Description">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field>
                    <input matInput formControlName="value" placeholder="Value">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field style="margin-right: 5px;">
                    <input matInput [matDatepicker]="pickerVan" placeholder="Geldig Van" formControlName="validFrom">
                    <mat-datepicker-toggle matSuffix [for]="pickerVan"></mat-datepicker-toggle>
                    <mat-datepicker #pickerVan></mat-datepicker>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field style="margin-right: 5px;">
                    <input matInput [matDatepicker]="pickerTot" placeholder="Geldig Tot" formControlName="validUntil">
                    <mat-datepicker-toggle matSuffix [for]="pickerTot"></mat-datepicker-toggle>
                    <mat-datepicker #pickerTot></mat-datepicker>
                  </mat-form-field>
                </td>
                <td>
                  <button mat-icon-button (click)="SecretToevoegen(c)">
                    <mat-icon>arrow_upward</mat-icon>
                  </button>

                </td>
              </tr>

            </tbody>
          </table>
        </form>

      </div>




    </div>
  </mat-card>
</div>
