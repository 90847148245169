import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Resource } from '../models/resource';
import { AppUser } from '../models/app-user';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  constructor(private http: HttpClient, private appConfigSvc: AppConfigService) { }

  get() {
    return this.http.get<Resource[]>(this.appConfigSvc.getConfig().apiRoot + '/Resource');
  }

  fetch(id) {
    return this.http.get<Resource>(this.appConfigSvc.getConfig().apiRoot + '/Resource/' + id);
  }

  getBeheerders(id) {
    return this.http.get<AppUser[]>(this.appConfigSvc.getConfig().apiRoot + '/Resource/' + id + '/AppUsers');
  }

  addBeheerder(resourceId: number, appUserId: number) {
    return this.http.put<AppUser[]>(this.appConfigSvc.getConfig().apiRoot + '/Resource/' + resourceId + '/AppUsers/' + appUserId, appUserId);
  }

  removeBeheerder(resourceId: number, appUserId: number) {
    return this.http.delete<AppUser[]>(this.appConfigSvc.getConfig().apiRoot + '/Resource/' + resourceId + '/AppUsers/' + appUserId);
  }


  save(resource: Resource) {
    if (!resource.resourceId) {
      return this.http.post<Resource>(this.appConfigSvc.getConfig().apiRoot + '/Resource', resource);
    } else {
      return this.http.put<Resource>(this.appConfigSvc.getConfig().apiRoot + '/Resource/' + resource.resourceId, resource);
    }
  }

}
