import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { IdentityProvider } from '../models/identity-provider';

@Injectable({
  providedIn: 'root'
})
export class IdentityProviderService {

  constructor(private http: HttpClient, private appConfigSvc: AppConfigService) { }

  get() {
    return this.http.get<IdentityProvider[]>(this.appConfigSvc.getConfig().apiRoot + '/IdentityProvider');
  }

  fetch(id) {
    return this.http.get<IdentityProvider>(this.appConfigSvc.getConfig().apiRoot + '/IdentityProvider/' + id);
  }

  save(idp: IdentityProvider) {

    if (idp.identityProviderId) {
      return this.http.put<IdentityProvider>(this.appConfigSvc.getConfig().apiRoot + '/IdentityProvider/' + idp.identityProviderId, idp);
    } else {
      return this.http.post<IdentityProvider>(this.appConfigSvc.getConfig().apiRoot + '/IdentityProvider', idp);
    }
    
  }

}
