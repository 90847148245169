<app-toolbar appinfo="appinfo" titel="Beheer Devices"></app-toolbar>
<div class="content" fxLayout="column">

  <button mat-raised-button (click)="RecordToevoegen()" style="margin: 8px; margin-left:0; width:300px;">Device toevoegen</button>

  <div>
    <form [formGroup]="filterForm">
      <mat-form-field>
        <mat-icon class="clickable" (click)="filterGewijzigd()" matSuffix>search</mat-icon>
        <input type="text" placeholder="Naam" aria-label="Naam" matInput
               formControlName="Naam" (change)="filterGewijzigd()">
      </mat-form-field>

    </form>

  </div>

  <table mat-table matSort [dataSource]="dataSource">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="Details(row)" class="clickable"></tr>


    <ng-container matColumnDef="deviceTokenId">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.deviceTokenId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="naam">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Naam</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.naam }}
      </td>
    </ng-container>
    <ng-container matColumnDef="passkey">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Toegangscode</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.passkey }}
      </td>
    </ng-container>
    <ng-container matColumnDef="geldig">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Geldig</th>
      <td mat-cell *matCellDef="let x;" style="padding-right: 5px;">
        {{ x.geldig }}
      </td>
    </ng-container>

  </table>

</div>
