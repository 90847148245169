<app-toolbar appinfo="appinfo" titel="Beheer Relying Party"></app-toolbar>

<div class="content" fxLayout="column">

  <mat-card style="margin-top:8px;">
    <div fxLayout="row">
      <button mat-raised-button color="primary" (click)="RecordOpslaan()" [disabled]="!rpForm.dirty" style="margin: 8px;">Opslaan</button>
      <mat-icon class="dirtyIcon" *ngIf="rpForm.dirty">edit</mat-icon>

      <button mat-raised-button color="warn" (click)="RecordVerwijderen()" [disabled]="rpForm.dirty || !rp.deviceCodeIdpRpId" style="margin: 8px;">Verwijderen</button>
      
    </div>

    <div style="padding: 8px;">
      <form [formGroup]="rpForm">
        <div fxLayout="column wrap">

          <mat-form-field>
            <input matInput formControlName="deviceCodeIdpRpId" placeholder="Id" readonly>
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="naam" placeholder="Naam" required>
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="client_Id" placeholder="Client Id" required>
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="client_Secret" placeholder="Client Secret">
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="redirectUri" placeholder="Redirect Uri" required>
          </mat-form-field>

        </div>
      </form>
    </div>
  </mat-card>

  
</div>
